/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 154번째 로그에서는 메모 문서 서비스, BMW 구독 모델에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://roamresearch.com/"
  }, "Roam Research – A note taking tool for networked thought.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://evernote.com/intl/ko"
  }, "최고의 노트 필기 앱 - Evernote로 노트를 정리하세요 - Evernote")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://whooing.com/"
  }, "후잉가계부")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.sbs.co.kr/news/endPage.do?news_id=N1006822404"
  }, "'열선 시트' 쓰려면 매달 돈 더 내라?…BMW에 소비자 '분노' - SBS 뉴스")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
